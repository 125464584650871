@import url("https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2&display=swap");
body {
  margin: 0;
  font-family: "Baloo Tammudu 2", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: var(--image-gradient), url(./Assets/earth.jpg);   
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
